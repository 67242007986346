import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full/shared/loadable/Loadable';
import Zone from 'src/views/zone';
import Area from 'src/views/area';
import Notification from 'src/views/Notification/Notification';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank/BlankLayout')));

/* ****Pages***** */
const Dashboard = Loadable(lazy(() => import('../views/dashboard/Dashboard')))

const CampList = Loadable(lazy(() => import('../views/camp/index')))
const DoctorList = Loadable(lazy(() => import('../views/doctor/index')))
const Employee = Loadable(lazy(() => import('../views/employee/index')))
const HQList = Loadable(lazy(() => import('../views/hq/index')))
const RegionList = Loadable(lazy(() => import('../views/region/index')))
const AddCampScreen =  Loadable(lazy(() => import('../views/camp/AddCampScreen')))
const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const Register = Loadable(lazy(() => import('../views/authentication/ForgetPassword')));
const Login = Loadable(lazy(() => import('../views/authentication/Login')));
const Patient =  Loadable(lazy(() => import('../views/patient/index')));
const CampPatientList =  Loadable(lazy(() => import('../views/camp/CampPatientList')));

const Router = [
  {
    path: '/',
    element: <BlankLayout />,
    children: [
      { path: '/', element: <Navigate to="/login" /> },
      { path: '404', element: <Error /> },
      { path: '/forgetPassword', element: <Register /> },
      { path: '/login', element: <Login /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
  {
    path: '/home',
    element: <FullLayout />,
    children: [
      { path: '/home/dashboard', exact: true, element: <Dashboard /> },
      { path: '/home/camp', exact: true, element: <CampList /> },
      { path: '/home/camp/:id', exact: true, element: <CampPatientList /> },
      { path: '/home/camp/editCamp', exact: true, element: <AddCampScreen /> },
      { path: '/home/hq', exact: true, element: <HQList /> },
      { path: '/home/region', exact: true, element: <RegionList /> },
      { path: '/home/employee', exact: true, element: <Employee /> },
      { path: '/home/doctor', exact: true, element: <DoctorList /> },
      { path: '/home/patient', exact: true, element: <Patient /> },
      { path: '/home/myProfile', exact: true, element: <Dashboard /> },
      { path: '/home/zone', exact: true, element: <Zone /> },
      { path: '/home/area', exact: true, element: <Area /> },
      { path: '/home/notification', exact: true, element: <Notification /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
 
];

export default Router;
