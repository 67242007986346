import axios from 'axios';
// import { API_URL, CAMP_URL, EMP_HQ_URL, EMP_MY_URL, FORGOT_PASSWORD, HQ, LOGIN_URL, PATIENT_DETAILS_NUMBER, PATIENT_HISTORY, REGION, SAVE_PATIENT } from 'src/service/routes';
import { API_URL, CAMP_URL, EMP_HQ_URL, EMP_MY_URL, FORGOT_PASSWORD, LOGIN_URL, PATIENT_DETAILS_NUMBER, PATIENT_HISTORY, SAVE_PATIENT,DOCTOR_URL, REGION, HQ, EMP_URL, DASHBOARD_CAMP, DASHBOARD_CAMP_STATUS, DASHBOARD_CAMP_PATIENT, DASHBOARD_TOP_DOCTOR, DASHBOARD_TOP_EMP, ZONE, AREA, DOCTOR_CODE_URL
  ,CAMP_TYPE_URL,
  HQ_DELETE,
  DASHBOARD_CAMPCOUNT,
  DASHBOARD_CAMP_DOCTOR,
  DASHBOARD_CAMP_EMPLOYEE,
  DASHBOARD_CAMP_STOCK,
  CAMP_CREATE_URL,
  CAMP_UPDATE_URL,
  CAMP_APPROVED_URL,
  CAMP_REJECTED_URL,
  DOCTOR_DELETE,
  EMP_FIND_URL,
  CAMP_Gallery_URL,
  DASHBOARD_REPORTUSERDATA,
  DASHBOARD_REPORTCAMPDATA,
  DASHBOARD,
  DASHBOARD_CAMP_STATUS_MAP


} from 'src/service/routes';
const headers = {
  'Content-Type': 'application/json',
  'Authorization': ''
}

  export const getGallery = async (campId) => {
    headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;
    const response = await axios.get(`${API_URL}${CAMP_Gallery_URL}/${campId}`,{
      headers: headers
    });
    return response.data;
  };
  export const getPatientByCamp = async (campId) => { 
    headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;
    const response = await axios.get(`${API_URL}${CAMP_URL}/patient/${campId}`,{
      headers: headers
    });
    return response.data;
  };
  export const deleteEmpplyee = async (empId) => { 
    headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;
    const response = await axios.delete(`${API_URL}${EMP_URL}/delete/${empId}`,{
      headers: headers
    });
    return response.data;
  };

  export const deleteCampItems = async (campId) => { 
    headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;
    const response = await axios.delete(`${API_URL}${CAMP_URL}/delete/${campId}`,{
      headers: headers
    });
    return response.data;
  };
  export const approvedCampItems = async (campId) => { 
    headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;
    const response = await axios.get(`${API_URL}${CAMP_APPROVED_URL}/${campId}`,{
      headers: headers
    });
    return response.data;
  };
  export const rejectCampItems = async (campId,comment) => { 
    headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;
    const response = await axios.post(`${API_URL}${CAMP_REJECTED_URL}/${campId}`,{
      "comment":comment
    },{
      headers: headers
    });
    return response.data;
  };
  export const deleteAreaItems = async (areaId) => { 
    headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;
    const response = await axios.delete(`${API_URL}${AREA}/${areaId}`,{
      headers: headers
    });
    return response.data;
  };
  export const deleteRegionItems = async (regionid) => { 
    headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;
    const response = await axios.delete(`${API_URL}${REGION}/${regionid}`,{
      headers: headers
    });
    return response.data;
  };
  export const deleteDoctor = async (Doctorid) => { 
    headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;
    const response = await axios.delete(`${API_URL}${DOCTOR_DELETE}/${Doctorid}`,{
      headers: headers
    });
    return response.data;
  };
  export const deleteHQItems = async (HQid) => { 
    headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;
    const response = await axios.delete(`${API_URL}${HQ}/${HQid}`,{
      headers: headers
    });
    return response.data;
  };
  export const deleteZoneItems = async (Zoneid) => { 
    headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;
    const response = await axios.delete(`${API_URL}${ZONE}/${Zoneid}`,{
      headers: headers
    });
    return response.data;
  };
  export const updateCampFile = async (camp_id,campDate, 
    campTypes, 
    campTime, 
    docterId,
    campAddress,
    patExp,
    campMaterial,
    campMaterialQty,
    campMaterialExp) => {
      headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;
    const response = await axios.put(`${API_URL}${CAMP_UPDATE_URL}/${camp_id}`,{
      camp_date:campDate,
      camp_type:campTypes,
      camp_time:campTime,
      camp_doctor:docterId,
      camp_address:campAddress,
      camp_expected:patExp,
      camp_material:campMaterial,
      material_unit:campMaterialQty,
      plain_quantity:campMaterialExp
    },{
      headers: headers
    });
    return response.data;
  };
  export const saveCampDetails = async (campDate, campType, campTime, doctorCode, campAddress, patientsExpected,camp_material,
    material_unit,plain_quantity,createdBy) => {
      headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;

    const response = await axios.post(`${API_URL}${CAMP_CREATE_URL}`,{
      camp_date:campDate,
      camp_type:campType,
      camp_time:campTime,
      camp_doctor:doctorCode,
      camp_address:campAddress,
      camp_expected:patientsExpected,
      camp_material:camp_material,
      material_unit:material_unit,
      plain_quantity:plain_quantity,
      createdBy
    },{
      headers: headers
    });
    return response.data;
  };
  export const getDoctorDetails = async (code) => { 
    headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;
    const response = await axios.get(`${API_URL}${DOCTOR_CODE_URL}/${code}`,{
      headers: headers
    });
    return response.data;
  };
  export const getEmpDetails = async (code) => { 
    headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;
    const response = await axios.get(`${API_URL}${EMP_URL}/code/${code}`,{
      headers: headers
    });
    return response.data;
  };
  export const login = async (code, pin) => {
    const response = await axios.post(`${API_URL}${LOGIN_URL}`,{
      emp_code : code,
      emp_pin:pin
    }); 
    return response.data;
  };

    
  export const getRegionByZone = async (ZoneId) => {
    headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;
    const response = await axios.get(`${API_URL}${CAMP_URL}/region/zone/${ZoneId}`,{
      headers: headers
    });
    return response.data;
  };

  export const foundReportUser = async (emp_role_id,emp_area_id,regionId,zoneId) => {
    headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;
    let data = {
      emp_role_id:emp_role_id
    }
    if(emp_area_id){
      data.emp_area_id = emp_area_id
    }
    if(regionId){
      data.regionId = regionId
    }
    if(zoneId){
      data.zoneId = zoneId
    }
    const response = await axios.post(`${API_URL}${EMP_FIND_URL}`,data,{
      headers: headers
    });
    return response.data;
  };
  export const getloadAllEmp = async () => {
    headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;
    const response = await axios.get(`${API_URL}${EMP_URL}`,{
      headers: headers
    });
    return response.data;
  };
  export const getloadZone = async () => {
    headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;
    const response = await axios.get(`${API_URL}${ZONE}`,{
      headers: headers
    });
    return response.data;
  };
  export const getReportloadZone = async () => {
    headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;
    const response = await axios.get(`${API_URL}${ZONE}`,{
      headers: headers
    });
    return response.data;
  };
  
  export const getHQ = async () => {
    headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;
    const response = await axios.get(`${API_URL}${EMP_HQ_URL}`,{
      headers: headers
    });
    return response.data;
  };
  
  export const getZoneByHQ = async (HQ_Id) => {
    headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;
    const response = await axios.get(`${API_URL}${CAMP_URL}/zone/hq/${HQ_Id}`,{
      headers: headers
    });
    return response.data;
  };
  export const getloadRegionByZone = async (zoneId) => {
    headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;
    const response = await axios.get(`${API_URL}${CAMP_URL}/region/zone/${zoneId}`,{
      headers: headers
    });
    return response.data;
  };
  export const getloadAreaByRegion = async (region_id) => {
    headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;
    const response = await axios.get(`${API_URL}${CAMP_URL}/area/region/${region_id}`,{
      headers: headers
    });
    return response.data;
  };
  export const getloadEmployeeByArea = async (area_id) => {
    headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;
    const response = await axios.get(`${API_URL}${EMP_URL}/area/${area_id}`,{
      headers: headers
    });
    return response.data;
  };
  export const getUserInfo = async () => {
    headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;
    const response = await axios.get(`${API_URL}${EMP_MY_URL}`,{
      headers: headers
    });
    return response.data;
  };
  export const getCampSummary = async (page,selCampType,campStart,campEnd) => { 
    headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;
    const response = await axios.post(`${API_URL}${CAMP_URL}/list/${page}`,{
      campType:selCampType,
      startDate:campStart,
      endDate:campEnd
    },{
      headers: headers
    });
    return response.data;
  };
  export const getloadPatient = async () => {
    const response = await axios.get(`${API_URL}${PATIENT_DETAILS_NUMBER}`);
    return response.data;
  }
  export const getloadPatientHistory = async (id) => {
    const apiUrl = `${API_URL}${PATIENT_HISTORY}/${id}`;
    const response = await axios.get(apiUrl);
    return response.data;
  }

  export const getDoctor = async (page) => {
    headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;
    const apiUrl = `${API_URL}${DOCTOR_URL}/list/${page}`;
    const response = await axios.get(apiUrl,{
      headers: headers
    });
    return response.data;
  }
  
  export const endDoctor = async (doctor_code,doctor_name,doctor_degree,doctor_id,specialist,category) => {
    headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;
    const response = await axios.put(`${API_URL}${DOCTOR_URL}/${doctor_id}`,{
      doctor_code,
      doctor_name,
      doctor_degree,
      specialist,
      category
    },{
      headers: headers
    });
    return response.data;
  }
  export const saveDoctor = async (doctor_code,doctor_name,doctor_degree,specialist,category) => {
    headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;
    const response = await axios.post(`${API_URL}${DOCTOR_URL}`,{
      doctor_code,
      doctor_name,
      doctor_degree,
      specialist,
      category
    },{
      headers: headers
    });
    return response.data;
  }
  
  export const EditEmp = async (emp_code,emp_pin,emp_role_id,emp_area_id,emp_hq_id,emp_report_id,emp_name,emp_email,emp_phone,zoneId,regionId,EmpId) => {
    headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;
    let data = {
      emp_code,
      emp_pin,
      emp_role_id,
      emp_area_id,
      emp_hq_id,
      emp_report_id,
      zoneId,
      regionId
    };
    if(emp_name){data.emp_name=emp_name}
    if(emp_email){data.emp_email=emp_email}
    if(emp_phone){data.emp_phone=emp_phone}
    const response = await axios.put(`${API_URL}${EMP_URL}/update/${EmpId}`,data,{
      headers: headers
    });
    return response.data;
  }
  export const saveEmp = async (emp_code,emp_pin,emp_role_id,emp_area_id,emp_hq_id,emp_report_id,emp_name,emp_email,emp_phone,zoneId,regionId) => {
    headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;
    let data = {
      emp_code,
      emp_pin,
      emp_role_id,
      emp_area_id,
      emp_hq_id,
      emp_report_id,
      zoneId,
      regionId
    };
    if(emp_name){data.emp_name=emp_name}
    if(emp_email){data.emp_email=emp_email}
    if(emp_phone){data.emp_phone=emp_phone}
    const response = await axios.post(`${API_URL}${EMP_URL}/create`,data,{
      headers: headers
    });
    return response.data;
  }
  export const getEmployee = async (page,selRole,selZone,selRegion,selArea) => {
    headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;
    const response = await axios.post(`${API_URL}${EMP_URL}/list/${page}`,{
      role:selRole,
      zone:selZone,
      region:selRegion,
      area:selArea
    },{
      headers: headers
    });
    return response.data;
  }



  export const getPatient = async (page) => {
    headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;
    const response = await axios.get(`${API_URL}${PATIENT_DETAILS_NUMBER}/list/${page}`,{
      headers: headers
    });
    return response.data;
  }

  export const forgotPassword = async (email) => {

    const response = await axios.post(`${FORGOT_PASSWORD}`,{
      emp_email : email
    });
    return response.data;
  };
  
  export const saveHQ = async (hq_name) => {
    const response = await axios.post(`${API_URL}${HQ}`,{
      hq_name
    },{
      headers: headers
    });
    return response.data;
  }
  export const editHQ = async (hq_name,hq_id) => {
    const response = await axios.put(`${API_URL}${HQ}/${hq_id}`,{
      hq_name
    },{
      headers: headers
    });
    return response.data;
  }
 
  export const editRegion = async (region_name,zoneId,id) => {
    const response = await axios.put(`${API_URL}${REGION}/${id}`,{
      region_name,
      zoneId
    },{
      headers: headers
    });
    return response.data;
  }
  export const saveRegion = async (region_name,zoneId) => {
    const response = await axios.post(`${API_URL}${REGION}`,{
      region_name,
      zoneId
    },{
      headers: headers
    });
    return response.data;
  }
  
  export const editZone = async (zoneName,zoneId) => {
    const response = await axios.put(`${API_URL}${ZONE}/${zoneId}`,{
      zoneName,
    },{
      headers: headers
    });
    return response.data;
  }
  export const saveZone = async (zoneName) => {
    const response = await axios.post(`${API_URL}${ZONE}`,{
      zoneName
    },{
      headers: headers
    });
    return response.data;
  }
  export const saveArea = async (area_name,regionId) => {
    const response = await axios.post(`${API_URL}${AREA}/create`,{
      area_name,
      regionId
    },{
      headers: headers
    });
    return response.data;
  }
  export const editArea = async (area_name,regionId,id) => {
    const response = await axios.put(`${API_URL}${AREA}/${id}`,{
      area_name,
      regionId
    },{
      headers: headers
    });
    return response.data;
  }
  
  export const getloadArea = async (zone,region) => {
    headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;
    const response = await axios.post(`${API_URL}${AREA}`,{
      zone,
      region
    },{
      headers: headers
    });
    return response.data;
  }
  export const getloadHQ = async () => {
    headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;
    const response = await axios.get(`${API_URL}${HQ}`,{
      headers: headers
    });
    return response.data;
  }
  export const getloadRegion = async () => {
    headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;
    const response = await axios.get(`${API_URL}${REGION}`,{
      headers: headers
    });
    return response.data;
  }
  
  export const getDashboardStock = async (area,reagion,zone,camp) => {
    headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;
    let url = "?";
    if(area){
      url = `${url}ABM=${area}`;
    }else if(reagion){
      url = `${url}RBM=${reagion}`;
    }else if(zone){
      url = `${url}zone=${zone}`;
    }
    if(camp){
      url = url=="?"?`${url}camp=${camp}`:`${url}&camp=${camp}`;
    }
    const response = await axios.get(`${API_URL}${DASHBOARD_CAMP_STOCK}${url}`,{
      headers: headers
    });
    return response.data;
  }
  export const getDashboardEmployee = async (area,reagion,zone,camp) => {
    headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;
    let url = "?";
    if(area){
      url = `${url}ABM=${area}`;
    }else if(reagion){
      url = `${url}RBM=${reagion}`;
    }else if(zone){
      url = `${url}zone=${zone}`;
    }
    if(camp){
      url = url=="?"?`${url}camp=${camp}`:`${url}&camp=${camp}`;
    }
    const response = await axios.get(`${API_URL}${DASHBOARD_CAMP_EMPLOYEE}${url}`,{
      headers: headers
    });
    return response.data;
  }
  export const getDashboardDoctor = async (area,reagion,zone,camp) => {
    headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;
    let url = "?";
    if(area){
      url = `${url}ABM=${area}`;
    }else if(reagion){
      url = `${url}RBM=${reagion}`;
    }else if(zone){
      url = `${url}zone=${zone}`;
    }
    if(camp){
      url = url=="?"?`${url}camp=${camp}`:`${url}&camp=${camp}`;
    }
    const response = await axios.get(`${API_URL}${DASHBOARD_CAMP_DOCTOR}${url}`,{
      headers: headers
    });
    return response.data;
  }
  
  export const getReportCampData = async (area,reagion,zone,camp,start,end) => {
    headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;
    let url = "?";
    if(area){
      url = `${url}ABM=${area}`;
    }else if(reagion){
      url = `${url}RBM=${reagion}`;
    }else if(zone){
      url = `${url}zone=${zone}`;
    }
    if(camp){
      url = url=="?"?`${url}camp=${camp}`:`${url}&camp=${camp}`;
    }
    if(start){
      url = url=="?"?`${url}start=${start}`:`${url}&start=${start}`;
    }
    if(end){
      url = url=="?"?`${url}end=${end}`:`${url}&end=${end}`;
    }
    const response = await axios.get(`${API_URL}${DASHBOARD_REPORTCAMPDATA}${url}`,{
      headers: headers
    });
    return response.data;
  }
  export const getReportUserData = async (area,reagion,zone,camp,start,end) => {
    headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;
    let url = "?";
    if(area){
      url = `${url}ABM=${area}`;
    }else if(reagion){
      url = `${url}RBM=${reagion}`;
    }else if(zone){
      url = `${url}zone=${zone}`;
    }
    if(camp){
      url = url=="?"?`${url}camp=${camp}`:`${url}&camp=${camp}`;
    }
    if(start){
      url = url=="?"?`${url}start=${start}`:`${url}&start=${start}`;
    }
    if(end){
      url = url=="?"?`${url}end=${end}`:`${url}&end=${end}`;
    }
    const response = await axios.get(`${API_URL}${DASHBOARD_REPORTUSERDATA}${url}`,{
      headers: headers
    });
    return response.data;
  }
  export const getDashboardCamps = async (area,reagion,zone,camp) => {
    headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;
    let url = "?";
    if(area){
      url = `${url}ABM=${area}`;
    }else if(reagion){
      url = `${url}RBM=${reagion}`;
    }else if(zone){
      url = `${url}zone=${zone}`;
    }
    if(camp){
      url = url=="?"?`${url}camp=${camp}`:`${url}&camp=${camp}`;
    }
    const response = await axios.get(`${API_URL}${DASHBOARD_CAMPCOUNT}${url}`,{
      headers: headers
    });
    return response.data;
  }
  export const getDashboardCamp = async (area,reagion,zone,camp,start,end) => {
    headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;
    let url = "?";
    if(area){
      url = `${url}ABM=${area}`;
    }else if(reagion){
      url = `${url}RBM=${reagion}`;
    }else if(zone){
      url = `${url}zone=${zone}`;
    }
    if(start){
      url = `${url}&start=${start}`;
    }
    if(end){
      url = `${url}&end=${end}`;
    }
    if(camp){
      url = url=="?"?`${url}camp=${camp}`:`${url}&camp=${camp}`;
    }
    const response = await axios.get(`${API_URL}${DASHBOARD_CAMP}${url}`,{
      headers: headers
    });
    return response.data;
  }
  export const getDashboardCampStatus = async (area,reagion,zone,camp,start,end) => {
    headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;
    const response = await axios.post(`${API_URL}${DASHBOARD_CAMP_STATUS_MAP}`,{
      area:area,
      reagion:reagion,
      zone:zone,
      start:start,
      end:end,
      camp:camp
    },{
      headers: headers
    });
    return response.data;
  }
  
  export const getDashboardStatus = async (area,reagion,zone,camp) => {
    headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;
    let url = "?";
    if(area){
      url = `${url}ABM=${area}`;
    }else if(reagion){
      url = `${url}RBM=${reagion}`;
    }else if(zone){
      url = `${url}zone=${zone}`;
    }
    if(camp){
      url = url=="?"?`${url}camp=${camp}`:`${url}&camp=${camp}`;
    }
    const response = await axios.get(`${API_URL}${DASHBOARD_CAMP_STATUS}${url}`,{
      headers: headers
    });
    return response.data;
  }
  export const getDashboardPatient= async (area,reagion,zone,camp) => {
    headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;
    let url = "?";
    if(area){
      url = `${url}ABM=${area}`;
    }else if(reagion){
      url = `${url}RBM=${reagion}`;
    }else if(zone){
      url = `${url}zone=${zone}`;
    }
    if(camp){
      url = url=="?"?`${url}camp=${camp}`:`${url}&camp=${camp}`;
    }
    const response = await axios.get(`${API_URL}${DASHBOARD_CAMP_PATIENT}${url}`,{
      headers: headers
    });
    return response.data;
  }
  export const getDashboardTopDoctor = async (area,reagion,zone,camp) => {
    headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;
    let url = "?";
    if(area){
      url = `${url}ABM=${area}`;
    }else if(reagion){
      url = `${url}RBM=${reagion}`;
    }else if(zone){
      url = `${url}zone=${zone}`;
    }
    if(camp){
      url = url=="?"?`${url}camp=${camp}`:`${url}&camp=${camp}`;
    }
    const response = await axios.get(`${API_URL}${DASHBOARD_TOP_DOCTOR}${url}`,{
      headers: headers
    });
    return response.data;
  }
  export const getDashboardTopEmp = async (area,reagion,zone,camp) => { 
    headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;
    let url = "?";
    if(area){
      url = `${url}ABM=${area}`;
    }else if(reagion){
      url = `${url}RBM=${reagion}`;
    }else if(zone){
      url = `${url}zone=${zone}`;
    }
    if(camp){
      url = url=="?"?`${url}camp=${camp}`:`${url}&camp=${camp}`;
    }
    const response = await axios.get(`${API_URL}${DASHBOARD_TOP_EMP}${url}`,{
      headers: headers
    });
    return response.data;
  }

  export const geCampType = async () => {
    headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;
    const response = await axios.get(`${API_URL}${CAMP_TYPE_URL}`,{
      headers: headers
    });
    return response.data;
  }
  export const sendNotification = async(title,body,zoneId=0,reagionId=0,areaId=0,employeeId=0)=>{
    const response = await axios.post(`${API_URL}${EMP_URL}/notification`,{
      title : title,
      body:body,
      zoneId,
      reagionId,
      areaId,
      employeeId
    }); 
    return response.data;
  }
  export const getDashboard = async (areaId,reagionId,zoneId,campId,campStart,campEnd) => {
    headers.Authorization = `Bearer ${await sessionStorage.getItem("usertoken")}`;
    const response = await axios.post(`${API_URL}${DASHBOARD}`,{
      areaId,reagionId,
      zoneId,campId,
      campStart,campEnd
    },{
      headers: headers
    });
    return response.data;
  }
  