

export const API_URL = "https://3ccampapp.com/V1/api/";

export const LOGIN_URL =`login`;
export const FCM_Token_URL =`emp/token`;
export const EMP_HQ_URL =`emp/hq`;
export const EMP_MY_URL =`emp/my`;

export const EMP_URL =`emp`;
export const EMP_FIND_URL =`emp/find`;
export const CAMP_Gallery_URL =`camp/gallery`;
export const CAMP_URL =`camp`;
export const HQ =`camp/hq`;
export const REGION =`camp/region`;
export const ZONE =`camp/zone`;
export const AREA =`camp/area`;

export const CAMP_TYPE_URL =`camp/type`;
export const CAMP_CREATE_URL =`camp/create`;
export const CAMP_UPLOAD_URL =`camp/upload`;
export const CAMP_UPDATE_URL =`camp/update`;

export const CAMP_DELETE_URL =`camp/delete`;
export const CAMP_APPROVED_URL =`camp/approved`;
export const CAMP_REJECTED_URL =`camp/rejected`;
export const CAMP_END_URL =`camp/end`;
export const CAMP_START =`camp/start`;
export const Add_PATIENT ='patient/create';
export const SAVE_PATIENT ='patient/add';
export const DOCTOR_URL =`doctor`;
export const DOCTOR_DELETE =`doctor/delete`;
export const DOCTOR_CODE_URL =`doctor/code`;
export const FORGOT_PASSWORD =`forget`;

export const PATIENT_DETAILS_NUMBER = `patient`
export const PATIENT_HISTORY = `patient/HISTORY`

export const DASHBOARD_REPORTCAMPDATA = `dashboard/reportCampData`
export const DASHBOARD_REPORTUSERDATA = `dashboard/reportUserData`
export const DASHBOARD_CAMPCOUNT = `dashboard/campsCount`
export const DASHBOARD_CAMP_DOCTOR = `dashboard/campDoctor`
export const DASHBOARD_CAMP_EMPLOYEE = `dashboard/campEmployee`
export const DASHBOARD_CAMP_STOCK = `dashboard/campStock`

export const DASHBOARD_CAMP = `dashboard/camp`
export const DASHBOARD_CAMP_STATUS_MAP = `dashboard/campstatusMap`
export const DASHBOARD_CAMP_STATUS = `dashboard/campstatus`
export const DASHBOARD_CAMP_PATIENT = `dashboard/camppatient`
export const DASHBOARD_TOP_DOCTOR = `dashboard/topdoctor`
export const DASHBOARD_TOP_EMP = `dashboard/topemp`
export const DASHBOARD =`dashboard/info`;