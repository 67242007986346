import React, { useEffect, useState } from 'react';
import { Grid, Box, Typography, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import PageContainer from 'src/components/container/PageContainer';
import CustomTextField from 'src/components/forms/theme-elements/CustomTextField';
import { getRegionByZone, getloadAreaByRegion, getloadEmployeeByArea, getloadZone, sendNotification } from 'src/service/auth';
import styled from '@emotion/styled';


const Notification = () => {
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const [title,setTitle] = useState("");
  const [body,setBody] = useState("");

  const [areaList,setAreaList] = useState([]);
  const [reagionList,setReagionList] = useState([]);
  const [zoneList,setZoneList] = useState([]);
  const [employeeList,setEmployeeList] = useState([]);

  const [areaId,setAreaId] = useState(null);
  const [reagionId,setReagionId] = useState(null);
  const [zoneId,setZoneId] = useState(null);
  const [employeeId,setEmployeeId] = useState(null);

  useEffect(() => {
    if( userInfo.emp_role_id ==5){
      loadZone();
    }else if( userInfo.emp_role_id ==4){
      loadReagion(userInfo.zoneId);
    }else if( userInfo.emp_role_id ==3){
      loadArea(userInfo.regionId);
    }else if( userInfo.emp_role_id ==2){
      loadEmployee(userInfo.emp_area_id);
    }  
  }, []);
  const loadZone = async () => {
    try {
      const campSummary = await getloadZone();
      setZoneList(campSummary);
     // const campS = await getReportloadZone();
     // setExcelData(campS)
    } catch (error) {
      console.error("Error fetching camp summary:", error);
    }
  };
  const loadReagion = async(zoneId)=>{
    try {
      const campSummary = await getRegionByZone(zoneId);
      setReagionList(campSummary);
    } catch (error) {
      console.error("Error fetching camp summary:", error);
    }
  }
  const loadArea = async(regionId)=>{
    try {
      const campSummary = await getloadAreaByRegion(regionId);
      setAreaList(campSummary);
    } catch (error) {
      console.error("Error fetching camp summary:", error);
    }
  }
  const loadEmployee = async(areaId)=>{
    try {
      const campSummary = await getloadEmployeeByArea(areaId);
      setEmployeeList(campSummary);
    } catch (error) {
      console.error("Error fetching camp summary:", error);
    }
  }
  const onClickUserValidate = async()=>{
    let user = await sendNotification(title, body,zoneId,reagionId,areaId,employeeId); 
  }
  const StyledFormControl = styled(FormControl)({
    margin: "8px",
    minWidth: "120px",
  });
  return (
    <PageContainer title="Camp" description="this is Camp List">
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
              <Box mt="25px">
                    <Typography variant="subtitle1"
                        fontWeight={600} component="label" htmlFor='password' mb="5px" >Title</Typography>
                    <CustomTextField  value={title} onChange={(event) => { setTitle(event.target.value);}} id="password"  variant="outlined" fullWidth />
              </Box>
              <Box mt="25px">
                    <Typography variant="subtitle1"
                        fontWeight={600} component="label" htmlFor='password' mb="5px" >Body</Typography>
                    <CustomTextField  value={body} onChange={(event) => { setBody(event.target.value);}} id="password"  variant="outlined" fullWidth />
              </Box>
              <Grid container style={{marginTop:20,marginBottom:20}}>
              {
                userInfo.emp_role_id ==5 &&   <Grid item xs={3} lg={3}><StyledFormControl variant="outlined" style={{ margin: "8px",width:"100%" }}>
                    <InputLabel>Zone</InputLabel>
                    <Select value={zoneId} onChange={(event)=>{
                        setZoneId(event.target.value)
                        loadReagion(event.target.value)
                    }} label="Gender">
                        {
                            zoneList && zoneList.map(e=>(
                                    <MenuItem value={e.zoneId}>{e.zoneName}</MenuItem>
                            ))
                        }
                    </Select>
                </StyledFormControl></Grid>
              }
              {
                userInfo.emp_role_id > 3 &&   <Grid item xs={3} lg={3}><StyledFormControl variant="outlined" style={{ margin: "8px",width:"100%" }}>
                    <InputLabel>RBM</InputLabel>
                    <Select value={reagionId} onChange={(event)=>{
                        setReagionId(event.target.value)
                        loadArea(event.target.value)
                    }} label="Gender">
                        {
                            reagionList && reagionList.map(e=>(
                                    <MenuItem value={e.region_id}>{e.region_name}</MenuItem>
                            ))
                        }
                    </Select>
                </StyledFormControl></Grid>
              }
              {
                userInfo.emp_role_id > 2 &&   <Grid item xs={3} lg={3}><StyledFormControl variant="outlined" style={{ margin: "8px",width:"100%" }}>
                    <InputLabel>ABM</InputLabel>
                    <Select value={areaId} onChange={(event)=>{
                        setAreaId(event.target.value)
                        loadEmployee(event.target.value)
                    }} label="Gender">
                        {
                            areaList && areaList.map(e=>(
                                    <MenuItem value={e.area_id}>{e.area_name}</MenuItem>
                            ))
                        }
                    </Select>
                </StyledFormControl></Grid>
              }
                <Grid item xs={3} lg={3}><StyledFormControl variant="outlined" style={{ margin: "8px",width:"100%" }}>
                    <InputLabel>Employee</InputLabel>
                    <Select value={employeeId} onChange={(event)=>{
                        setEmployeeId(event.target.value)
                    }} label="Gender">
                        {
                            employeeList && employeeList.map(e=>(
                                    <MenuItem value={e.emp_id}>{e.emp_name} {e.emp_code} ({e.emp_role_id==1?"BO":e.emp_role_id==2?"AM":e.emp_role_id==3?"RM":"ZM"})</MenuItem>
                            ))
                        }
                    </Select>
                </StyledFormControl></Grid>
            </Grid>
              <Box >
                <Button
                    color="primary"
                    variant="contained"
                    size="large"
                    fullWidth
                    onClick={onClickUserValidate}
                    type="submit"
                >
                    Send
                </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </PageContainer>
  );
};

export default Notification;
