import React from 'react';
import { Grid, Box } from '@mui/material';
import PageContainer from 'src/components/container/PageContainer';

// components
import ZoneList from './ZoneList';


const Zone = () => {
  return (
    <PageContainer title="Camp" description="this is Camp List">
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <ZoneList />
          </Grid>
        </Grid>
      </Box>
    </PageContainer>
  );
};

export default Zone;
