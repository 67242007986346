import React, { useEffect, useState } from 'react';
import {
    Typography, Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    IconButton,
    Button,
    TextField,
    InputLabel,
    Select,
    FormControl,
    MenuItem,
} from '@mui/material';
import Modal from '@mui/material/Modal';
import DashboardCard from '../../components/shared/DashboardCard';
import { deleteZoneItems, editZone, getloadHQ, getloadRegion, getloadZone, saveHQ, saveZone } from 'src/service/auth';
import { useNavigate } from 'react-router';
import { Edit, Delete } from '@mui/icons-material';
import styled from '@emotion/styled';
import { API_URL } from 'src/service/routes';



const ZoneList = () => {
    const userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
    const [zone, setZone] = useState("");
    const [campData, setCampData] = useState([]);
    const [open, setOpen] = useState(false);
    const [id, setId] = useState("");

    const handleOpen = async () => {
        if(zone==""){
            alert("Please Enter Zone")
        }
        if(id==""){
            await saveZone(zone);
        }else{
            await editZone(zone,id);
        }
        setZone("")
        setId("")
        loadCampSummary();
        setOpen(false)
    }
    const handleClose = () => {
        setId("")
        setZone("")
        setOpen(false);
    };
    const handleZoneChange = (event) => {
    setZone(event.target.value);
    };
      
    useEffect(() => {
      loadCampSummary();
    }, []);
    const loadCampSummary = async () => {
      try {
        const campSummary = await getloadZone();
        setCampData(campSummary);
      } catch (error) {
        console.error("Error fetching camp summary:", error);
      }
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };

    const openEditBox = (item)=>{
        setZone(item.zoneName);
        setId(item.zoneId)
        setOpen(true);
    }
    const deleteZone = async(item)=>{
        let text = "Are you sure delete Zone";
        if (window.confirm(text) == true) {
            await deleteZoneItems(item.zoneId);
            loadCampSummary();
        }
    }
    const handleGenerateExcel = ()=>{
        window.open(`${API_URL}camp/generate_excel_by_zone?role_id=${userInfo.emp_role_id}`, '_blank')
      }
    return (

        <DashboardCard title="Zone">
            <Box sx={{ overflow: 'auto', width: { xs: '280px', sm: 'auto' } }}>
                  <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                   <Button style={{marginRight:10}} variant="contained" color="primary" onClick={()=>setOpen(true)}>
                   Add Zone
                   </Button>
                   <Button style={{width:150,height:40}}  variant="contained" color="primary"  onClick={handleGenerateExcel}>
                        Generate Excel
                    </Button>
               </Box>
                <Table
                    aria-label="simple table"
                    sx={{
                        whiteSpace: "nowrap",
                        mt: 2
                    }}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography variant="subtitle2" fontWeight={600}>
                                  Name
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="subtitle2" fontWeight={600}>
                                    
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {campData!=null && campData.map((product) => (
                            <TableRow key={product.zoneId}>
                                <TableCell>
                                    <Typography
                                        sx={{
                                            fontSize: "15px",
                                            fontWeight: "500",
                                        }}
                                    >
                                        {product.zoneName}
                                    </Typography>
                                </TableCell>

                                <TableCell>
                                    <IconButton aria-label="edit" onClick={()=>openEditBox(product)}>
                                        <Edit />
                                    </IconButton>
                                    <IconButton aria-label="delete" onClick={()=>deleteZone(product)}>
                                        <Delete />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <TextField
                            label="Zone Name"
                            variant="outlined"
                            value={zone}
                            onChange={handleZoneChange}
                            style={{ margin: "8px",width:"100%" }}
                        />

                        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                            <Button variant="contained" style={{marginRight:10}}  color="primary" onClick={handleOpen}>
                                {id!=""?'Edit':'Save'}
                            </Button>

                            <Button variant="contained" color="secondary" onClick={()=>setOpen(false)}>
                                {"Close"} 
                            </Button>
                        </Box>
                    </Box>
                </Modal>
            </Box>
        </DashboardCard>
    );
};

export default ZoneList;
